import React from 'react';

const StyledList = ({ items }) => {
    
    return (
        <section className="c-styled-list u-bg--black">
            <div className="container">
                <div className="row">
                    <div class="col-lg-8 offset-lg-2">
                        <div class="row">
                            {items.list.map((item) => (
                                <div className="col-lg-4">
                                    <div className="c-styled-list__item">
                                        <div className="c-styled-list__title">{item.title}</div>
                                        <div className="c-styled-list__text">{item.text}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StyledList;
