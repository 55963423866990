import React, { useEffect } from 'react';
import Img from "gatsby-image";


const ProcessWithImage = ({ items }) => {
    return (
        <div className="row c-process-with-image">
            <div className="col-md-6">
                <h3 className="c-process-with-image__title">{items.header}</h3>
         
                {items.process && (
                    <ul>
    			        {items.process.map(({ header, number, text }, index) => (
                            <li key={index}>
                                {header && (
                                    <h4>{number && (<span>{number}.</span>)} {header}</h4>
                                )}
                                {text && (<p>{text}</p>)}
                            </li>
                        ))}
                    </ul>
                )}
            
            </div>
            
            <div className="col-md-6 c-process-with-image__image">
                <Img key={items.image}
                    fluid={items.image.imageFile.childImageSharp.fluid}
                    alt={items.image.altText} />
            </div>
        </div>
    );
};

export default ProcessWithImage;
