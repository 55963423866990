import React, { useEffect, useState, useRef, useContext } from 'react';
import Img from "gatsby-image";
import Button from '../components/component.button';
import ThemeContext from "../context/context.theme";


const TwoImageFullWidthWithText = ({ items }) => {

    const [loaded, setLoaded] = useState(false);
    const video = useRef(null);
    const theme = useContext(ThemeContext);
    const hasLeftVideo = (items.videoLeftMp4 || items.videoLeftWebm) ? theme.allowVideo : false;
    const hasRightVideo = (items.videoRightMp4 || items.videoRightWebm) ? theme.allowVideo : false;

    useEffect(() => {
        if (video && video.current) {
            // Add callback when video is loaded if the video isn't already loaded
            if (video.current.readyState === 0) {
                video.current.addEventListener('loadeddata', () => {
                    setLoaded(true);
                });
            } else {
                setLoaded(true);
            }
        } else {
            setLoaded(true);
        }
    }, []);

    return (
        <section className="c-full-width-images-text">
         
            {(items.imageLeft || hasLeftVideo) && (
                <div className="c-full-width-images-text__image">
                    {hasLeftVideo && (
                        <video ref={video} className="c-hero__video"
                            poster={items.imageLeft ? items.imageLeft.imageFile.childImageSharp.fluid.src : ''}
                            muted="muted" playsinline="playsinline" autoPlay loop>
                            {items.videoLeftMp4 && (<source src={items.videoLeftMp4.mediaItemUrl} type="video/mp4"/>)}
                            {items.videoLeftWebm && (<source src={items.videoLeftWebm.mediaItemUrl} type="video/webm"/>)}
                            <Img key={items.imageLeft}
                            fluid={items.imageLeft.imageFile.childImageSharp.fluid}
                            alt={items.imageLeft.altText} />
                        </video>
                    )}
                    {!hasLeftVideo && (
                        <Img key={items.imageLeft}
                            fluid={items.imageLeft.imageFile.childImageSharp.fluid}
                            alt={items.imageLeft.altText} />
                    )}
                    <div className="hoverOverlay"></div>
                    <div className="c-full-width-images-text__text c-full-width-images-text__text--left">
                        {items.headerLeft && (
                            <h1 className="c-full-width-images-text__title">{items.headerLeft}</h1>
                        )}
                        {items.textLeft && (
                            <span className="c-full-width-images-text__subtitle">{items.textLeft}</span>
                        )}
                        {items.buttonLeft && (
                            <Button link to={items.buttonLeft.url} text={items.buttonLeft.title}/>
                        )}
                    </div>
                    
                </div>
            )}
            
            
            {(items.imageRight || hasRightVideo) && (
                <div className="c-full-width-images-text__image">
                    {hasRightVideo && (
                        <video ref={video} className="c-hero__video"
                            poster={items.imageRight ? items.imageRight.imageFile.childImageSharp.fluid.src : ''}
                            muted="muted" playsinline="playsinline" autoPlay loop>
                            {items.videoRightMp4 && (<source src={items.videoRightMp4.mediaItemUrl} type="video/mp4"/>)}
                            {items.videoRightWebm && (<source src={items.videoRightWebm.mediaItemUrl} type="video/webm"/>)}
                            <Img key={items.imageRight}
                            fluid={items.imageRight.imageFile.childImageSharp.fluid}
                            alt={items.imageRight.altText} />
                        </video>
                    )}
                    {!hasRightVideo && (
                        <Img key={items.imageRight}
                            fluid={items.imageRight.imageFile.childImageSharp.fluid}
                            alt={items.imageRight.altText} />
                    )}
                    <div className="hoverOverlay"></div>
                    <div className="c-full-width-images-text__text">
                        {items.headerRight && (
                            <h1 className="c-full-width-images-text__title">{items.headerRight}</h1>
                        )}
                        {items.textRight && (
                            <span className="c-full-width-images-text__subtitle">{items.textRight}</span>
                        )}
                        {items.buttonRight && (
                            <Button link to={items.buttonRight.url} text={items.buttonRight.title}/>
                        )}
                    </div>
                </div>
            )}
        </section>
    );
};

export default TwoImageFullWidthWithText;