import React from 'react';
import { useTrail } from 'react-spring';
import { ParallaxLayer } from 'react-spring/renderprops-addons';
import { useInView } from 'react-intersection-observer';
import Img from "gatsby-image";

const speeds = [
    "-0.03",
    "0.0",
    "0.03",
    "0.03"
];

const Intro = ({ parallax, items }) => {
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true,
    });

    // fixes issue where parallax isn't used
    if (items.parallaxImages === undefined) {
        items.parallaxImages = [];
    }

    const imageTrail = useTrail(items.parallaxImages.length, {
        config: { tension: 280, friction: 60 },
        opacity: inView ? 1 : 0,
        transform: inView ? 'scale(1)' : 'scale(0)',
        from: { opacity: 0, transform: 'scale(0)' },
    });

    return (
        <div>
            <div className={parallax ? "c-intro c-intro--parallax" : "c-intro"}>
                <div className="container">
                    <div ref={ref} className="c-intro__details" dangerouslySetInnerHTML={{ __html: items.content }}></div>
                </div>
                <div className="c-intro__images d-none d-lg-block">
                    {parallax && imageTrail.map(({ ...rest }, index) => (
                        <>
                            {items.parallaxImages[index].image &&
                                <ParallaxLayer key={index} className={`c-intro__image c-intro__image--` + index} offset="0" speed={speeds[index]}>
                                    <Img key={index}
                                        fluid={items.parallaxImages[index].image.imageFile.childImageSharp.fluid}
                                        alt={items.parallaxImages[index].image.altText} />
                                </ParallaxLayer>
                            }
                        </>
                    ))}
                    {!parallax && imageTrail.map(({ ...rest }, index) => (
                        <>
                            {items.parallaxImages[index].image &&
                                <div key={index} className={`c-intro__image c-intro__image--` + index}>
                                    <Img key={index}
                                        fluid={items.parallaxImages[index].image.imageFile.childImageSharp.fluid}
                                        alt={items.parallaxImages[index].image.altText} />
                                </div>
                            }
                        </>
                    ))}
                </div>
            </div>
        </div>
    );

};

export default Intro;
