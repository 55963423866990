import React, { useEffect, useState } from 'react';
import addToMailchimp from "gatsby-plugin-mailchimp";
import { withTranslation } from 'react-i18next';

import useCountriesData from "../../hooks/hook.useCountriesData";
import useFetchPost from "../../hooks/hook.useFetchPost";
import Button from '../component.button';


const DemoForm = ({ t }) => {
    const countries = useCountriesData();
    const [formSubmitted, setFormSubmitted] = useState(false);

    const defaultContactDetails = {
        interestedBoat: '',
        country: '',
        phoneNumber: '',
        contactName: '',
        club: '',
        email: '',
        weight: '',
        purchaseType: '',
        howHearOption: '',
        howHear: '',
        howHearOther: '',
        newsletter: false,
    }
    const [contactDetails, setContactDetails] = useState(defaultContactDetails);

    const handleInputChange = event => {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        // handle checkbox
        if (name === 'newsletter') {
            value = target.checked;
        }

        setContactDetails({
            ...contactDetails,
            [name]: value,
        });
    };

    const interestedBoatOptions = [
        t("contact.demo-form.race-single-boat"),
        t("contact.demo-form.race-pair-boat"),
        t("contact.demo-form.race-four-boat"),
        'Racing Coxed Four/Quad',
        t("contact.demo-form.race-eight-boat"),
        t("contact.demo-form.learn-single-boat"),
        t("contact.demo-form.learn-pair-boat"),
        t("contact.demo-form.learn-four-boat"),
        t("contact.demo-form.learn-sup-boat"),
        t("contact.demo-form.adaptive-single-boat"),
        t("contact.demo-form.adaptive-pair-boat"),
        t("contact.demo-form.adaptive-four-boat"),
        'Coastal Single',
        'Coastal Double',
        'Coastal Quad',
        t("contact.demo-form.coaching-boat"),
    ];

    const purchaseTypeOptions = [
        t("contact.demo-form.private-type"),
        t("contact.demo-form.club-type"),
        t("contact.demo-form.school-type"),
        t("contact.demo-form.uni-type"),
        t("contact.demo-form.national-type"),
        t("contact.demo-form.other-type"),
    ]

    const howHearOptions = [
        t("contact.demo-form.currently-how"),
        t("contact.demo-form.other-club-how"),
        t("contact.demo-form.regatta-how"),
        t("contact.demo-form.facebook-how"),
        t("contact.demo-form.instagram-how"),
        t("contact.demo-form.twitter-how"),
        t("contact.demo-form.google-how"),
        t("contact.demo-form.youtube-how"),
        t("contact.demo-form.world-rowing-how"),
        t("contact.demo-form.row-360-how"),
        t("contact.demo-form.row-2k-how"),
        t("contact.demo-form.rowing-news-how"),
        t("contact.demo-form.other-spec-how"),
    ]

    const formSubmission = useFetchPost({
        path: '/wp-json/contact-form-7/v1/contact-forms/1919/feedback',
        data: contactDetails,
        submitClick: formSubmitted
    });

    const handleSubmit = async event => {
        event.preventDefault();

        if (contactDetails.howHearOption === t("contact.demo-form.other-spec-how")) {
            contactDetails.howHear = contactDetails.howHearOther;
        } else {
            contactDetails.howHear = contactDetails.howHearOption;
        }

        // add the selected class/hull button selections
        setContactDetails({
            ...contactDetails,
        });

        if (contactDetails.newsletter) {
            addToMailchimp(contactDetails.email, { 'PAGEID': 'Demo' });
        }


        // submit the form
        await setFormSubmitted(true);
        // revert the form submitted to default state;
        await setFormSubmitted(false);
    };

    useEffect(() => {
        // clear the form if it's successful
        if (formSubmission[0].success === true) {
            formSubmission[0].success = false;
            setContactDetails(defaultContactDetails);
        }
    }, [formSubmitted, formSubmission]);

    return (
        <div className="container xs-pb-60">
            <div className="row">
                <div className="col-12">
                    <div className="c-form c-contact-form">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">

                                <div className="c-contact-form__header">
                                    <h2>{t("contact.demo-form.title")}</h2>
                                </div>

                                <form className="c-form" onSubmit={handleSubmit}>

                                    <div className="row">

                                        <div className="col-sm-12">
                                            <div className="c-input-holder">
                                                <div className="c-input-holder__wrapper">
                                                    <select
                                                        className="c-input-holder__select"
                                                        id="interestedBoat"
                                                        name="interestedBoat"
                                                        value={contactDetails.interestedBoat}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value=''>{t("contact.demo-form.boat-option")}</option>
                                                        {interestedBoatOptions.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <input
                                                    className={contactDetails.contactName.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="contactName"
                                                    name="contactName"
                                                    value={contactDetails.contactName}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="c-input-holder__label" htmlFor="contactName">{t("contact.demo-form.name-label")}</label>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <input
                                                    className={contactDetails.email.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="contactEmail"
                                                    name="email"
                                                    value={contactDetails.email}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="c-input-holder__label" htmlFor="contactEmail">{t("contact.demo-form.email-label")}</label>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <input
                                                    className={contactDetails.phoneNumber.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="number"
                                                    id="contactPhoneNumber"
                                                    name="phoneNumber"
                                                    value={contactDetails.phoneNumber}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="c-input-holder__label" htmlFor="contactPhoneNumber">{t("contact.demo-form.phone-label")}</label>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <div className="c-input-holder__wrapper">
                                                    <select
                                                        className="c-input-holder__select"
                                                        id="contactCountry"
                                                        name="country"
                                                        value={contactDetails.country}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value=''>{t("contact.demo-form.country-option")}</option>
                                                        {countries.map((country, index) => (
                                                            <option key={index} value={country.ACFDealerBlockFields.country}>{country.ACFDealerBlockFields.country}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <input
                                                    className={contactDetails.club.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="club"
                                                    name="club"
                                                    value={contactDetails.club}
                                                    onChange={handleInputChange}
                                                />
                                                <label className="c-input-holder__label" htmlFor="club">{t("contact.demo-form.affiliated-label")} <span>({t("contact.demo-form.optional-span")})</span></label>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <input
                                                    className={contactDetails.weight.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="weight"
                                                    name="weight"
                                                    value={contactDetails.weight}
                                                    onChange={handleInputChange}
                                                />
                                                <label className="c-input-holder__label" htmlFor="weight">{t("contact.demo-form.weight-label")} <span>({t("contact.demo-form.optional-span")})</span></label>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <div className="c-input-holder__wrapper">
                                                    <select
                                                        className="c-input-holder__select"
                                                        id="purchaseType"
                                                        name="purchaseType"
                                                        value={contactDetails.purchaseType}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value=''>{t("contact.demo-form.type-option")}</option>
                                                        {purchaseTypeOptions.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <div className="c-input-holder__wrapper">
                                                    <select
                                                        className="c-input-holder__select"
                                                        id="howHearOption"
                                                        name="howHearOption"
                                                        value={contactDetails.howHearOption}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value=''>{t("contact.demo-form.how-option")}</option>
                                                        {howHearOptions.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className={contactDetails.howHearOption !== t("contact.demo-form.other-spec-how") ?  `c-input-holder c-input-holder--hidden` : 'c-input-holder'}>
                                                <input
                                                    className={contactDetails.howHearOther.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="howHearOther"
                                                    name="howHearOther"
                                                    value={contactDetails.howHearOther}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="c-contact-form__footer">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="c-input-holder__checkbox-wrapper xs-mb-20 md-mb-0">
                                                    <div>
                                                        <input
                                                            className="c-input-holder__checkbox"
                                                            type="checkbox"
                                                            name="newsletter"
                                                            id="newsletter"
                                                            checked={contactDetails.newsletter}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <label className="c-input-holder__label--checkbox" htmlFor="newsletter">{t("contact.demo-form.tick-label")}</label>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="c-contact-form__submit">
                                                    <Button text={t("contact.demo-form.button-label")} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(DemoForm);
