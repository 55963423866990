import React from 'react';
import Img from "gatsby-image";


const FullWidthImage = ({ items }) => {

    return (
        <>
            {items.image && (
                <section className="c-full-width-image">
                    <Img key={items.image}
                         fluid={items.image.imageFile.childImageSharp.fluid}
                         alt={items.image.altText} />
                </section>
            )}
        </>
    );
};

export default FullWidthImage;
