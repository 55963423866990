import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { withTranslation } from 'react-i18next';

import Button from '../components/component.button';

const CTABanner = ({ title='', linkLabel='', linkUrl={ url: '/downloads/' }, image, description=false, t }) => {
    const defaultBannerImage = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "default-cta-banner.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 735) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    const titleText = title != '' ? title : t('cta-banner.title');
    const linkLabelText = linkLabel != '' ? linkLabel : t('cta-banner.link-label');

    const bannerImage = image ? image.imageFile.childImageSharp.fluid : defaultBannerImage.file.childImageSharp.fluid;
    const bannerAlt = image ? image.altText : t('cta-banner.banner-alt');

    return (
        <div className="container">
            <div className="c-banner">
                <Img className="c-banner__image" fluid={bannerImage} alt={bannerAlt} />
                <div className="c-banner__details">
                    <span className="c-banner__title">{titleText}</span>
                    {description && (
                        <p className="c-banner__description">{description}</p>
                    )}
                    <Button link className="c-banner__button" to={linkUrl.url} text={linkLabelText} />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(CTABanner);
