import React from 'react';
import Img from "gatsby-image";

const videoEmbedWithTitle = ({ items }) => {

    return (
        <section className="c-full-width-video-embed">
          <div className="container xs-pt-30 xs-pb-60 lg-pt-50 lg-pb-100">
            <div className="row">
              <div className="col-12">
                {items.title && (
                    <div className="c-full-width-video-embed__title">
                        <h2>{items.title}</h2>

                        {items.subtitle && (
                            <h3>{items.subtitle}</h3>
                        )}
                    </div>
                )}

                {items.videoEmbed && (
                    <div className="video">
                        <div className="placeholder">
                            <Img key={items.imagePlaceholder}
                                fluid={items.imagePlaceholder.imageFile.childImageSharp.fluid}
                                alt={items.imagePlaceholder.altText} />
                        </div>
                        <iframe
                          src={items.videoEmbed}
                          title={items.title}
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          frameBorder="0"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          allowFullScreen
                        />
                    </div>
                )}
              </div>
            </div>
          </div>
        </section>
    );
};

export default videoEmbedWithTitle;