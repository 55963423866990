import React, { useEffect, useState, useRef, useContext } from 'react';
import Img from "gatsby-image";
import ThemeContext from "../context/context.theme";


const ContentLeft = ({ items }) => {
    
    const [loaded, setLoaded] = useState(false);
    const video = useRef(null);
    const theme = useContext(ThemeContext);
    const hasVideo = (items.videoMp4 || items.videoWebm) ? theme.allowVideo : false;

    useEffect(() => {
        if (video && video.current) {
            // Add callback when video is loaded if the video isn't already loaded
            if (video.current.readyState === 0) {
                video.current.addEventListener('loadeddata', () => {
                    setLoaded(true);
                });
            } else {
                setLoaded(true);
            }
        } else {
            setLoaded(true);
        }
    }, []);

    return (
        <div className="container lg-mb-40">
            <div className="row">
                <div className="col-lg-4 offset-lg-2" dangerouslySetInnerHTML={{ __html: items.content }}></div>
                {items.image && !hasVideo && (
                    <div className="col-lg-6 xs-mb-20">
                        <Img key={items.image}
                             fluid={items.image.imageFile.childImageSharp.fluid}
                             alt={items.image.altText} />
                    </div>
                )}
                {hasVideo && (
                    <div className="col-lg-6 xs-mb-20">
                        <video ref={video}
                            poster={items.image ? items.image.imageFile.childImageSharp.fluid.src : ''}
                            controls playsinline="playsinline" loop>
                            {items.videoMp4 && (<source src={items.videoMp4.mediaItemUrl} type="video/mp4"/>)}
                            {items.videoWebm && (<source src={items.videoWebm.mediaItemUrl} type="video/webm"/>)}
                            <Img key={items.image}
                            fluid={items.image.imageFile.childImageSharp.fluid}
                            alt={items.image.altText} />
                        </video>
                    </div>
                )}
            </div>
        </div>
    );
    
};

export default ContentLeft;
