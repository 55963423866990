import React from 'react';
import CountUp  from "react-countup";
import { useInView } from "react-intersection-observer";

const Stats = ({ items }) => {

    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true,
    });
    
    return (
        <section className="c-stats" ref={ref} style={items.background_colour ? { background: items.background_colour } : {} }>
            <div className="container">
                <div className="row">
                    <div class="col-lg-8 offset-lg-2">
                        <div class="row">
                            {items.stats.map((item, index) => {
                                let amount = parseFloat(item.amount);
                                let suffix = '';
                                if((item.amount !== null) && (typeof item.amount !== "undefined")) {
                                    if (item.amount.includes('%')) {
                                        suffix = '%';
                                    }
                                }
                                return (
                                    <div className={index === 0 ? 'col-lg-4 col-xl-3 offset-xl-1' : 'col-lg-4 col-xl-3'}>
                                        <div className="c-stats__item">
                                            <div className="c-stats__amount">
                                                {inView && isNaN(amount) === false && (
                                                    <CountUp
                                                        end={amount}
                                                        decimals={1}
                                                        delay={0.5}
                                                        suffix={suffix}
                                                    />
                                                )}
                                                {inView && isNaN(amount) && (
                                                    <span>{item.amount}</span>
                                                )}
                                                {inView === false && (
                                                    <span>{suffix}</span>
                                                )}
                                            </div>
                                            <div className="c-stats__text" dangerouslySetInnerHTML={{__html: item.text}}></div>
                                        </div>
                                    </div>
                                )}
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Stats;

