import React from 'react';
import Img from "gatsby-image";

const ContentRight = ({ items }) => {
    
    return (
        <div className="container lg-mb-40">
            <div className="row">
                {items.image && (
                    <div className="col-lg-6 xs-mb-20">
                        <Img key={items.image}
                             fluid={items.image.imageFile.childImageSharp.fluid}
                             alt={items.image.altText} />
                    </div>
                )}
                <div className="col-lg-4" dangerouslySetInnerHTML={{ __html: items.content }}></div>
            </div>
        </div>
    );
    
};

export default ContentRight;
