import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { withTranslation } from 'react-i18next';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Instagram = ({ t }) => {
    // TODO: Can we add childImageSharp ??
    /*
    const instagramData = useStaticQuery(graphql`
        query instagram {
            wordpress {
                instagramImages(limit: 10) {
                    sourceUrl
                    link
                    caption    
                }
            }
        }
    `);

    const instagram = instagramData.wordpress.instagramImages;
    */

    const instagram = [];

    const sliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerMode: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            },

        ],
    };

    if (!instagram) {
        return null;
    }

    return (
        <div className="c-instagram">
            <div className="container">
                <div className="c-section-title">
                    <h2 className="c-section-title__title">{t("instagram.title")}</h2>
                    <p className="c-section-title__desc">{t("instagram.description-a")} <a className="c-section-title__link" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/wintech_racing/">{t("instagram.description-b")} <i className="c-section-title__arrow fal fa-long-arrow-right"></i></a></p>
                </div>

                <Slider className="c-instagram__slider" {...sliderSettings}>
                    {instagram.map(({ sourceUrl, caption, link }) => (
                        <>
                            {sourceUrl &&
                                <div key={sourceUrl} className="c-instagram__item">
                                    <a className="c-instagram__link" href={link} target="_blank" rel="noopener noreferrer" aria-label={t("instagram.button-label")}>
                                        <img className="c-instagram__image" alt={caption ? caption : t("instagram.image-alt")} src={sourceUrl} />
                                    </a>
                                </div>
                            }
                        </>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default withTranslation()(Instagram);