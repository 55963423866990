import React from 'react';

const BlockContent = ({ items }) => {
    return (
        <div className="c-block-content xs-mt-40 lg-mt-60 xs-mb-40 lg-mb-60 container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <div dangerouslySetInnerHTML={{ __html: items.content }}></div>
                </div>
            </div>
        </div>
    );
};

export default BlockContent;
