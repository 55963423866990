import React from 'react';

import Hero from './component.hero';
import Intro from './component.intro';
import CTABanner from './component.cta-banner';
import Info from './component.info';
import Categories from './component.categories';
import Latest from './component.latest';
import Instagram from './component.instagram';
import Clients from './component.clients';
import DemoForm from "./contact/component.demo-form";
import RowingBlazersForm from "./contact/component.rowing-blazers-form";
import BoysBoatForm from "./contact/component.boys-in-the-boat-form";
import KingRacingForm from "./contact/component.king-racing-form";
import CoastalBoatForm from "./contact/component.coastal-boat-form";
import FLXRacingForm from "./contact/component.flx-racing-form";
import BoatSlider from './component.boat-slider';
import StyledList from './component.styled-list';
import Stats from './component.stats';
import FullWidthImage from './component.full-width-image';
import BlockContent from "./component.block-content";
import ContentLeft from "./component.content-left";
import ContentRight from "./component.content-right";
import TwoImageFullWidth from "./component.two-image-full-width";
import TwoImageFullWidthWithText from "./component.two-image-full-width-with-text";
import videoEmbedWithTitle from "./component.video-embed-with-title";
import TwoImageOneThirdWithText from "./component.two-image-one-third-with-text";
import ProcessWithImage from "./component.process-with-image";

/**
 * Render components
 *
 * Renders the relevant components with the corresponding data for blocks added in the CMS
*/

const components = {
    'acf/slider': Hero,
    'acf/boat-finder-cta': CTABanner,
    'acf/intro': Intro,
    'acf/content-left-image-right': ContentLeft,
    'acf/content-right-image-left': ContentRight,
    'acf/why-wintech-racing': Info,
    'acf/product-categories': Categories,
    'acf/news-list': Latest,
    'acf/instagram': Instagram,
    'acf/logo-group': Clients,
    'acf/demo-form': DemoForm,
    'acf/rowing-blazers-form': RowingBlazersForm,
    'acf/boys-in-the-boat-form': BoysBoatForm,
    'acf/coastal-boat-form': CoastalBoatForm,
    'acf/king-racing-form': KingRacingForm,
    'acf/flx-racing-form': FLXRacingForm,
    'acf/styled-list': StyledList,
    'acf/stats': Stats,
    'acf/boat-slider': BoatSlider,
    'acf/full-width-image': FullWidthImage,
    'acf/custom-content': BlockContent,
    'acf/two-image': TwoImageFullWidth,
    'acf/video-embed': videoEmbedWithTitle,
    'acf/two-image-with-text': TwoImageFullWidthWithText,
    'acf/two-image-with-text-1-3': TwoImageOneThirdWithText,
    'acf/process-with-image': ProcessWithImage
};

const isEmpty = obj => {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
};

/**
 * Makes the data available directly at component.data.
 * Allows us to pass the data directly to the component
 * as we know where it's located.
 *
 * Also avoids error with unquie name not being available
 * when rendering multiple blocks.
 *
 * @param {*} component
 * @returns
 */

const convertACFProps = component => {
    Object.keys(component).forEach(key => {

        // Check if the component is using our acf data structure
        if (key.includes('ACF')) {
            if (component[key].hasOwnProperty('ComponentContainer')) {
                // this resolves the issue with the ComponentContainer on the same level as ComponentData
                if (component[key].ComponentContainer.length !== undefined) {
                    component.data = { items: component[key].ComponentContainer };
                    delete component[key];
                } else if (component[key].ComponentContainer.hasOwnProperty('ComponentData')) {
                    component.data = component[key].ComponentContainer.ComponentData;
                    delete component[key];
                }
            } else {
                // catch all
                component.data = { items: component[key] };
                delete component[key];
            }
        }
    });

    return component;
};

const ComponentParser = ({ blocks, parallax, language }) => {
    // An object is created for every block, even if that specific block is not returned
    // This will remove anything we don't want to render as a component (empty blocks/blocks without a name)

    if (!blocks) return null;
    blocks = blocks.filter(component => component.name != null);

    if (blocks && blocks.length > 0) {
        const pageComponents = blocks.map((component, index) =>
        {
            if (isEmpty(component)) return null;
            if (!component) return null;

            const componentName = component.name;
            const Component = components[componentName];

            if (!Component) return null;

            component = convertACFProps(component);

            return (
                <Component
                    index={index}
                    language={language}
                    key={`component-${index}`}
                    parallax={parallax}
                    {...component.data}
                />
            );
        });

        if (pageComponents) {
            return pageComponents;
        }
    }

    return null;
}

export default ComponentParser;