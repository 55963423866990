import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NonStretchedImage from "./component.non-stretched-image";

const BoatSlider = ({ items }) => {

    const sliderSettings = {
        dots: false,
        adaptiveHeight: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        fade: false,
        speed: 1750,
        autoplaySpeed: 1750,
    };
    
    return (
        <section className="c-boat-slider" style={items.background_colour ? { background: items.background_colour } : {} }>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {items.slider &&
                        <Slider {...sliderSettings}>
                            {items.slider.map((image, index) => (
                                <>
                                    {image.image && (
                                        <NonStretchedImage key={index} fluid={image.image.imageFile.childImageSharp.fluid} alt={image.altText} />
                                    )}
                                    {image.description && (
                                        <h3 className="c-boat-slider__description">{image.description}</h3>
                                    )}
                                    {image.logos && (
                                        <div className="c-boat-slider__logos">
                                            {image.logos.map((logo, logoIndex) => (
                                            <NonStretchedImage className="c-boat-slider__logos-image"
                                                               key={logoIndex}
                                                               alt={logo.altText}
                                                               fluid={{...logo.image.imageFile.childImageSharp.fluid,
                                                                   ...{presentationWidth: Math.floor(logo.image.mediaDetails.width/6)},
                                                                   // ...{presentationHeight: 50}
                                                               }} />
                                            ))}
                                        </div>
                                    )}
                                </>
                            ))}
                        </Slider>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
};

export default BoatSlider;
