import React from 'react';
import { useTrail, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import Img from "gatsby-image";

import Button from '../components/component.button';

const config = { mass: 5, tension: 2000, friction: 200 };

const Clients = ({ label, title, link, logos }) => {
    const [ref, inView] = useInView({
        threshold: .3,
        triggerOnce: true,
    });

    const trail = useTrail(logos.length, {
        config,
        opacity: inView ? 1 : 0,
        transform: inView ? 'scale(1)' : 'scale(0)',
        from: { opacity: 0, transform: 'scale(0)' },
    });
    
    return (
        <section className="c-clients u-bg--fixed u-bg--waved-texture">
            <div className="container">
                <span className="c-clients__title">{title}</span>

                <ul ref={ref} className="c-clients__list">
                    {trail.map(({ ...rest }, index) => (
                        <>
                            {logos[index].image &&
                                <animated.li key={index} style={rest} className="c-clients__item">
                                    <Img key={index} className="c-clients__image" fluid={logos[index].image.imageFile.childImageSharp.fluid} alt={logos[index].image.altText} />
                                </animated.li>
                            }
                        </>
                    ))}
                </ul>
                {link &&
                    <Button link to={link.url} text={label} />
                }
            </div>
        </section>
    );
};

export default Clients;
