import React, { useEffect, useState, useRef, useContext } from 'react';
import Img from "gatsby-image";
import Button from '../components/component.button';
import ThemeContext from "../context/context.theme";


const TwoImageOneThirdWithText = ({ items }) => {

    const [loaded, setLoaded] = useState(false);
    const video = useRef(null);
    const theme = useContext(ThemeContext);
    const hasLeftVideo = (items.videoLeftMp4 || items.videoLeftWebm) ? theme.allowVideo : false;
    const hasRightVideo = (items.videoRightMp4 || items.videoRightWebm) ? theme.allowVideo : false;

    useEffect(() => {
        if (video && video.current) {
            // Add callback when video is loaded if the video isn't already loaded
            if (video.current.readyState === 0) {
                video.current.addEventListener('loadeddata', () => {
                    setLoaded(true);
                });
            } else {
                setLoaded(true);
            }
        } else {
            setLoaded(true);
        }
    }, []);

    let dynamicClassName = 'c-full-one-third-images-text';
    if(items.largerSide == 'Right') {
        dynamicClassName += ' c-full-one-third-images-text--reversed';
    }
    if(items.addSpaceBeneath == 'Yes') {
        dynamicClassName += ' c-full-one-third-images-text--margin-bottom';
    }
    let imageLeftMobile = items.imageLeft;
    if(items.imageLeftMobile) {
        imageLeftMobile = items.imageLeftMobile;
    }
    let imageRightMobile = items.imageRight;
    if(items.imageRightMobile) {
        imageRightMobile = items.imageRightMobile;
    }

    return (
        <section className={dynamicClassName}>
         
            {(items.imageLeft || hasLeftVideo) && (
                <div className="c-full-one-third-images-text__image">
                    {hasLeftVideo && (
                        <video ref={video} className="c-hero__video"
                            poster={items.imageLeft ? items.imageLeft.imageFile.childImageSharp.fluid.src : ''}
                            muted="muted" playsinline="playsinline" autoPlay loop>
                            {items.videoLeftMp4 && (<source src={items.videoLeftMp4.mediaItemUrl} type="video/mp4"/>)}
                            {items.videoLeftWebm && (<source src={items.videoLeftWebm.mediaItemUrl} type="video/webm"/>)}
                            <Img key={items.imageLeft}
                            fluid={items.imageLeft.imageFile.childImageSharp.fluid}
                            alt={items.imageLeft.altText} />
                        </video>
                    )}
                    {!hasLeftVideo && 
                        <>
                            {(theme.screenType === 'desktop') &&
                                <Img key={items.imageLeft}
                                    fluid={items.imageLeft.imageFile.childImageSharp.fluid}
                                    alt={items.imageLeft.altText} />
                            }
                            {(theme.screenType !== 'desktop') &&
                                <Img key={imageLeftMobile}
                                    fluid={imageLeftMobile.imageFile.childImageSharp.fluid}
                                    alt={imageLeftMobile.altText} />
                            }
                        </>
                    }
                    <div className="hoverOverlay"></div>
                    <div className={(items.largerSide == 'Right' ? 'c-full-one-third-images-text__text' : 'c-full-one-third-images-text__text c-full-one-third-images-text__text--indent')}>
                        {items.headerLeft && (
                            <h1 className="c-full-one-third-images-text__title">{items.headerLeft}</h1>
                        )}
                        {items.textLeft && (
                            <span className="c-full-one-third-images-text__subtitle">{items.textLeft}</span>
                        )}
                        {items.buttonLeft && (
                            <Button link to={items.buttonLeft.url} text={items.buttonLeft.title}/>
                        )}
                    </div>
                    
                </div>
            )}
            
            {(items.imageRight || hasRightVideo) && (
                <div className="c-full-one-third-images-text__image">
                    {!hasRightVideo && 
                        <>
                            {(theme.screenType === 'desktop') &&
                                <Img key={items.imageRight}
                                    fluid={items.imageRight.imageFile.childImageSharp.fluid}
                                    alt={items.imageRight.altText} />
                            }
                            {(theme.screenType !== 'desktop') &&
                                <Img key={imageRightMobile}
                                    fluid={imageRightMobile.imageFile.childImageSharp.fluid}
                                    alt={imageRightMobile.altText} />
                            }
                        </>
                    }
                    <div className="hoverOverlay"></div>
                    <div className={(items.largerSide == 'Right' ? 'c-full-one-third-images-text__text c-full-one-third-images-text__text--indent' : 'c-full-one-third-images-text__text')}>
                        {items.headerRight && (
                            <h1 className="c-full-one-third-images-text__title">{items.headerRight}</h1>
                        )}
                        {items.textRight && (
                            <span className="c-full-one-third-images-text__subtitle">{items.textRight}</span>
                        )}
                        {items.buttonRight && (
                            <Button link to={items.buttonRight.url} text={items.buttonRight.title}/>
                        )}
                    </div>
                </div>
            )}
        </section>
    );
};

export default TwoImageOneThirdWithText;
